import { RouteAuthContext } from '@/_shared/router/types';
import MessagesMain from '@/messages/MessagesMain.vue';
import BasePlaceHolderView from '@/_shared/components/routing/BasePlaceHolderView.vue';
import { RouteLocationNormalized } from 'vue-router';
import MessageCreate from '@/messages/components/MessageCreate.vue';
import { createNewMessage } from '@/messages/types/messages';
import { storeToRefs } from 'pinia';
import useUserStore from '@/_shared/store/user';
import useMessagesStore from '@/messages/store/messagesStore';
import NewMessageButton from '@/messages/NewMessageButton.vue';
import { loadModuleLanguageFileAsync } from '@/_shared/translations/i18nLoading';
import MessageDetails from '@/messages/components/MessageDetails.vue';
import MessagesTopBar from '@/messages/components/MessagesTopBar.vue';

export const otherRoutes = [
  {
    path: '/messages',
    components: {
      TopBar: MessagesTopBar,
    },
    beforeEnter: async () => {
      useMessagesStore().$reset();
      await loadModuleLanguageFileAsync('messages'); // TODO WHEN PURE VUE remove
      await useMessagesStore().getMessages();
    },
    children: [
      {
        path: '',
        components: {
          Action: NewMessageButton,
        },
        children: [
          {
            path: '',
            name: 'messages',
            alias: ['sent', 'archived_inbox', 'archived_sent'],
            component: MessagesMain,
          },
          {
            path: 'new',
            name: 'messagesNew',
            component: MessageCreate,
            beforeEnter: async (to : RouteLocationNormalized, from : RouteLocationNormalized) => {
              const { currentUser } = storeToRefs(useUserStore());
              const { createMessage } = useMessagesStore();
              if (from.name !== 'messages.message') {
                const message = createNewMessage(currentUser.value.id);
                message.body = decodeURIComponent(to.query.body?.toString() ?? '');
                message.subject = decodeURIComponent(to.query.subject?.toString() ?? '');
                await createMessage(message);
              }
              return true;
            },
            props: true,
          },
          {
            path: ':messageId',
            name: 'messages.message',
            component: MessageDetails,
            props: true,
          },
        ],
      },
    ],
  },
  {
    path: '/dashboards',
    name: 'dashboards',
    meta: { isAngular: true },
    children: [
      {
        path: 'category',
        name: 'dashboards.category',
        children: [
          {
            path: ':pathMatch(.*)*',
            name: 'dashboards.category.subSection',
            component: BasePlaceHolderView,
          },
        ],
      },
      {
        // TODO:
        //  dashboards.report ???
        path: ':pathMatch(.*)*',
        name: 'dashboards.subSection',
        component: BasePlaceHolderView,
      },
    ],
  },
  {
    path: '/me',
    name: 'me',
    meta: { isAngular: true },
    component: BasePlaceHolderView,
    children: [
      {
        path: 'sections',
        name: 'me.sections',
        component: BasePlaceHolderView,
        children: [
          {
            path: 'general_info',
            name: 'me.sections.general_info',
            component: BasePlaceHolderView,

          },
          {
            path: 'access_information',
            name: 'me.sections.access_information',
            component: BasePlaceHolderView,
          },
          {
            path: 'settings',
            name: 'me.sections.settings',
            component: BasePlaceHolderView,
          },
        ],
      },
    ],
  },
  {
    path: '/onboarding',
    name: 'onboarding',
    component: BasePlaceHolderView,
    children: [
      {
        path: ':pathMatch(.*)*',
        name: 'onboarding.clients',
        component: BasePlaceHolderView,
      },
    ],
  },
  {
    path: '/alarms',
    name: 'alarms',
    component: BasePlaceHolderView,
    meta: { isAngular: true },
    children: [
      {
        path: ':state(\\d+)',
        name: 'alarms.state',
        component: BasePlaceHolderView,
      },
    ],
  },
];

export const allowedOtherRoutes = (authContext: RouteAuthContext) => {
  const allowedRoutes: string[] = [];

  allowedRoutes.push(
    'me',
    'me.sections',
    'me.sections.general_info',
    'me.sections.access_information',
    'me.sections.settings',
  );

  allowedRoutes.push(
    'onboarding',
    'onboarding.clients',
  );

  allowedRoutes.push(
    'alarms',
    'alarms.state',
  );

  if (authContext.getFeatureToggle('dashboard')
    && authContext.userHasPermission('viewDashboard')) {
    allowedRoutes.push(
      'dashboards',
      'dashboards.category',
      'dashboards.category.subSection',
      'dashboards.subSection',
    );
  }

  if (authContext.userHasPermission('viewHandovers')) {
    allowedRoutes.push(
      'handovers',
      'handovers.interaction',
      'handovers.subSection',
    );
  }

  if (authContext.userHasPermission('viewMessages')) {
    allowedRoutes.push(
      'messages',
      'messagesNew',
      'messages.sent',
      'messages.archived_inbox',
      'messages.message',
      'messages.archived_sent',
    );
  }

  return allowedRoutes;
};

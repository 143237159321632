import useUserStore from '@/_shared/store/user';
import { defineStore, storeToRefs } from 'pinia';
import {
  computed, ComputedRef, Ref, ref, watch,
} from 'vue';
import {
  createInteraction,
  fetchInteraction,
  newInteraction,
  recalculateComputedParameters,
  updateInteraction,
  getLastInteraction,

} from '@/timeline/services/interactionsApi';
import IInteraction from '@/timeline/types/IInteraction';
import {
  FileWrapper,
  Parameter,
  ShowIf,
  CoercedSelection,
  CoercedChoice,
  PictureWrapper, CoercedValue, CoercedSingleSelect, CoercedNourishInstance,
} from '@/timeline/types/Parameter';
import { Alarm } from '@/timeline/types/Alarm';
import {
  createFile,
  deleteFile, deleteGenericFile,
  softDeleteFile,
  updateFile,
} from '@/generic_file/services/GenericFileApi';
import {
  endOfDay, isAfter, isToday, parseISO,
} from 'date-fns';
import { valueToCamel } from '@/_shared/services/keysToCamel';
import { GenericFile } from '@/_shared/types/genericFile';
import use from '@/_shared/compositionApi';
import useInteractionsStore from '@/_shared/store/interactions';
import {
  getFirstConfigOption,
  hasMultipleChoices,
  isOldVersionSignatureParameter,
  parseNumberDataPointValues,
} from '@/timeline/helper/parametersHelper';
import { carerStore } from '@/_shared/store/carers';
import equal from 'fast-deep-equal/es6';
import { isManualOrTargetAlarm } from '@/timeline/helper/interactionsHelper';
import useParameterValidator from '@/timeline/helper/useParameterValidator';
import useCategoryIconStore from '@/_shared/store/categoryIcons';
import { uuid } from 'vue-uuid';
import { dataPointsStore } from '@/_shared/store/dataPoints';
import useMultiSelectRegister from '@/_shared/store/multiSelectRegister';
import useServiceFilteringStore from '@/_shared/store/serviceFiltering';
import { shallowEqual, until } from '@/_shared/services/UseUtils';
import { SkinInstance } from '@/_shared/types/NourishInstance';
import useAuditTrailStore from '@/_shared/store/auditTrail';
import useInteractionActionStore from '@/_shared/store/interactionActionsStore';

const { translate } = use.helpers();

const { clientStore } = use.store.clients();

const useCurrentInteractionStore = defineStore('currentInteraction', () => {
  const interactionStore = useInteractionsStore();
  const interactionActionsStore = useInteractionActionStore();

  const {
    userHasPermission,
    userCan,
    getFeatureToggle,
  } = useUserStore();

  const { getIconUrl } = useCategoryIconStore();
  const currentClientId = ref<number>();
  const { bulkInteractionIds } = storeToRefs(useMultiSelectRegister());
  const { currentUser } = storeToRefs(useUserStore());
  const currentInteraction: Ref<IInteraction> = ref({} as IInteraction);
  const initialInteraction: Ref<IInteraction> = ref({} as IInteraction);
  const iconUrl = computed(() => getIconUrl(currentInteraction.value.categoryCodename)?.value);
  const interactionLoaded = ref(false);
  const { interactions } = storeToRefs(useInteractionsStore());
  // set from query parameter
  const timelineDate = ref(new Date());
  const wasSavedInBackEnd = ref(false);
  const skinInstances = computed(() => {
    if (currentClientId.value || currentInteraction.value?.clientId) {
      return clientStore.skinInstances(currentClientId.value || currentInteraction.value?.clientId).value;
    }
    return [];
  });
  const shouldHideEmptyParameters = computed(() => currentInteraction.value.hideEmptyParameters && currentInteraction.value.state === 'closed');

  const archivedValues = ref<Record<string, boolean>>({});

  interface PictureToDelete {
    pictureFile: GenericFile,
    parameterId: number | string
  }

  const picturesToDelete: Ref<PictureToDelete[]> = ref([] as PictureToDelete[]);

  // **** computed Properties *******
  const isNotBulkClose = computed(() => !bulkInteractionIds.value.length);
  const isClosed = computed(() => currentInteraction.value?.state === 'closed');
  const isSticky = computed(() => currentInteraction.value?.state === 'sticky');
  const isCancelled = computed(() => currentInteraction.value?.state === 'cancelled');
  const isNew = computed(() => !currentInteraction.value?.id);

  const cannotBeClosed = computed(() => hasManualAlarm.value
    || (isClosed.value
      && (hasSavedSignatureParam.value || (hasAlarm.value || hasWarning.value))));

  const isPlannedInFuture = computed(() => {
    if (currentInteraction.value?.state !== 'planned') return false;
    if (!currentInteraction.value?.finishAt) return false;

    const now = new Date();
    const finishAt = new Date(currentInteraction.value?.finishAt);
    if (isNew.value) {
      return isAfter(finishAt, now);
    }
    return isAfter(finishAt, endOfDay(now));
  });

  const hasDocuments = computed(() => !!currentInteraction.value?.carePlanDocuments?.length || !!currentInteraction.value?.supportingDocuments?.length);
  const hasOldVersionSignatureParam = computed(() => currentInteraction.value?.parameters?.some(
    (p: Parameter) => p.valueType === 'signature' && isOldVersionSignatureParameter(p),
  ) || false);
  const hasSavedSignatureParam = computed(() => isClosed.value && hasOldVersionSignatureParam.value);
  const hasManualAlarm = computed(() => {
    const alarms = currentInteraction.value?.alarms;
    return (alarms && alarms.some((alarm: Alarm) => alarm.alarmType === 'manual')) || false;
  });
  const hasWarning = computed(() => _checkInteractionActiveAlarmsStates('warning'));
  const hasAlarm = computed(() => _checkInteractionActiveAlarmsStates('alarm'));
  const hasActions = computed(() => interactionActionsStore.interactionHasActions());
  const hasDataPoint = computed(() => (currentInteraction.value?.parameters
    && currentInteraction.value?.parameters.some((p: Parameter) => (p?.dataPoint && p?.dataPoint.length > 0) || p?.valueType === 'combined_multi_search')) || false);
  const hasNFC = computed(() => !!currentInteraction.value?.nfcTagId);

  const createdByAction = computed(() => currentInteraction.value?.protocolParentId !== null);
  const isClosedWithNoWarning = computed(() => isClosed.value && (!hasWarning.value && !hasAlarm.value));

  // TODO this logic could be calculated by and coming from backend ???
  const isViewOnly = computed(() => {
    const isReadOnlyAfterClose = !userHasPermission('editAfterInteractionClose') || (currentInteraction.value?.readOnlyAfterClose === true);
    const isReadOnly = (hasDataPoint.value || hasOldVersionSignatureParam.value || isReadOnlyAfterClose) && isClosed.value;
    const currentPersonCannotManageInteraction = !userCan('manageInteractionsForServiceId', currentInteraction.value?.serviceId);
    const aoFabAndIsCareplanReview = getFeatureToggle('aoFabMenu') && currentInteraction.value.serviceCodename === 'careplan_review';
    return currentPersonCannotManageInteraction || isReadOnly || aoFabAndIsCareplanReview;
  });

  const hasChanged = computed(() => {
    if (isViewOnly.value) return false;
    if (!initialInteraction.value.id || !currentInteraction?.value.id) return false;
    return initialInteraction.value.parameters
      .some((p: Parameter) => !shallowEqual(p.coercedValue, _getParameter(p.id)?.coercedValue))
      || initialInteraction.value.notePublic !== currentInteraction?.value.notePublic
      || !equal(initialInteraction.value.responsiblePersonIds, currentInteraction?.value.responsiblePersonIds);
  });

  const hasChangedForSignature = computed(() => {
    if (isViewOnly.value) return false;

    const initialParams = initialInteraction.value.parameters;
    const currentNotePublic = currentInteraction?.value.notePublic;
    const initialNotePublic = initialInteraction.value.notePublic;
    const anyParameterChanged = initialParams
      .filter((p) => p.valueType !== 'signature')
      .some((p) => !shallowEqual(p.coercedValue, _getParameter(p.id)?.coercedValue));
    const notePublicChanged = initialNotePublic !== currentNotePublic && !isCancelled.value;

    return anyParameterChanged || notePublicChanged;
  });

  const alarmTypes = ['due', 'target_not_met', 'manual'];
  const hasAnyCloseAlarmToDisplay = computed(() => {
    const alarms = currentInteraction.value?.alarms;
    return (alarms && alarms.some((alarm: Alarm) => isManualOrTargetAlarm(alarm, isClosed.value) && alarm.closed));
  });
  const closedAlarms = computed(() => currentInteraction.value.alarms
    ?.filter((a: Alarm) => alarmTypes.includes(a.alarmType as string) && a.closed)
    ?.map((alarm: Alarm) => ({
      ...alarm,
      closerName: carerStore.name(alarm.closerId as number),
      closerPhoto: carerStore.photo(alarm.closerId as number),
    })));
  const hasNourishInstanceParameter = computed(() => currentInteraction.value.parameters?.some((p: Parameter) => p.valueType === 'nourish_instance'));

  const hasFilledInSignature = computed(() => currentInteraction.value.parameters?.filter((p) => p.valueType === 'signature').some((p) => p.coercedValue !== null));
  const newSignatureCVSaved = ref(false);
  const showSignatureResetWarningModal = computed(() => hasChangedForSignature.value && hasFilledInSignature.value && !newSignatureCVSaved.value);

  watch(() => currentInteraction.value?.clientId, (newId, oldId) => {
    if (newId === oldId) {
      return;
    }
    currentClientId.value = newId;
  });

  // eslint-disable-next-line no-new
  function _checkInteractionActiveAlarmsStates(state: string) {
    const alarms = currentInteraction.value?.alarms;
    return (alarms && alarms.some((alarm: Alarm) => alarm.state === state)) || false;
  }

  function setPreviousInteractionState(interaction: IInteraction | undefined) {
    initialInteraction.value = JSON.parse(JSON.stringify(interaction));
  }

  function mergeDateAndTime(date: Date, time: Date) {
    const mergedDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), time.getHours(), time.getMinutes(), time.getSeconds());
    return mergedDate;
  }

  function _resetTimes(interaction: IInteraction) {
    let now = new Date().toISOString();
    if (timelineDate.value) {
      if (!isToday(timelineDate.value)) {
        now = mergeDateAndTime(timelineDate.value, new Date()).toISOString();
      }
    }
    if (interaction.state === 'planned') {
      if (!interaction.finishAt || isToday(parseISO(interaction.finishAt))) {
        interaction.finishAt = now;
      }
      if (interaction.needsStartAt) {
        if (!interaction.startAt || isToday(parseISO(interaction.startAt))) {
          interaction.startAt = now;
        }
      } else {
        interaction.startAt = null;
      }
    }
  }

  async function setCurrentInteraction(id: number) {
    // TODO better type below
    _resetStoreState();
    await fetchInteraction(id)
      .then((fetchedInteraction) => {
        const interaction = fetchedInteraction as IInteraction;
        if (getFeatureToggle('useUserAudit')) useAuditTrailStore().logInteractionAccess(interaction.id, interaction.clientId);
        currentInteraction.value = interaction;
        if (interaction.finishAt) timelineDate.value = new Date(interaction.finishAt);
        return interaction;
      }).then(async (interaction) => {
        await interactionActionsStore.$reset(interaction.serviceCodename);
        await _maybePatchParametersWithDataPoints();
        await prefillNourishInstanceParameters();
        setPreviousInteractionState(interaction);
        if (!interaction.needsStartAt) {
          interaction.startAt = null;
        }
        maybePatchCoercedChoices(interaction.parameters);
        if (bulkInteractionIds.value?.length) {
          interaction.responsiblePersonIds = [];
          interaction.defaultNotes = {};
          interaction.notePublic = '';
        }
      });
    interactionLoaded.value = true;
  }

  async function setNewInteraction(clientId: number, serviceId: number) {
    _resetStoreState();
    await newInteraction(clientId, serviceId)
      .then(async (fetchedInteraction) => {
        const interaction = fetchedInteraction as IInteraction;
        currentInteraction.value = interaction;
        _resetTimes(interaction);
        await prefillNourishInstanceParameters();
        return interaction;
      }).then(async (interaction) => {
        await interactionActionsStore.$reset(interaction.serviceCodename);
        await _maybePatchParametersWithDataPoints();
        maybePatchCoercedChoices(interaction.parameters);
        setPreviousInteractionState(interaction);
      });
    interactionLoaded.value = true;
  }

  function changeCurrentInteraction() {
    _resetStoreState();
    let newCurrentInteraction = interactions.value.find((interaction) => interaction.id === bulkInteractionIds.value[0]) as IInteraction;
    setPreviousInteractionState(newCurrentInteraction);
    newCurrentInteraction = {
      ...newCurrentInteraction,
      ..._getCommonProperties(currentInteraction.value),
    };
    currentInteraction.value = newCurrentInteraction;
    interactionLoaded.value = true;
  }

  function _getParameter(id: number | string): Parameter | undefined {
    return currentInteraction?.value?.parameters?.find((parameter: Parameter) => parameter.id === id);
  }

  function _parameterCodenamesMap(): Map<string, Parameter> {
    const parameterCodenames = new Map();
    currentInteraction?.value?.parameters.forEach((parameter) => {
      if (parameter.codename) {
        parameterCodenames.set(parameter.codename, parameter);
      }
    });
    return parameterCodenames;
  }

  function _allRequiredParametersFilledIn(interaction: IInteraction): boolean {
    // TODO HACK for now. We need to check that this interaction has actions and if it does then ignore the all requried filled check.
    if (interaction.parameters && currentInteraction.value.computable) return true;
    if (!interaction.parameters) return false;
    return interaction.parameters
      .filter((parameter: Parameter) => !parameter.hidden && parameter.required && parameter.coercedValue === null)
      .length === 0;
  }

  function _errorMessageExist(interaction: IInteraction): boolean {
    if (!interaction.parameters) return false;
    return interaction.parameters
      .filter((parameter: Parameter) => parameter.required && parameter.coercedValue !== null
        && useParameterValidator(parameter).errorMessage.value !== undefined)
      .length > 0;
  }

  async function _runCalculators(runForActions = false) {
    const response = await recalculateComputedParameters(currentInteraction.value, runForActions);
    if (!(response.computedParameters && currentInteraction.value)) return;
    if (runForActions) {
      currentInteraction.value.computedParameters = _getUniqueComputedParameters(response.computedParameters);
    } else {
      const existingActions = currentInteraction.value.computedParameters.filter((p) => p.id === 'actions');
      currentInteraction.value.computedParameters = _getUniqueComputedParameters([...response.computedParameters, ...existingActions]);
    }
  }

  const _getUniqueComputedParameters = (computedParameters: Parameter[]) => {
    const computedParameterMap = computedParameters?.map((computedParameter) => [computedParameter.id, computedParameter]);
    return [...new Map(computedParameterMap as []).values()] as Parameter[];
  };

  function _maybeClearDataPointParametersValue(interaction: IInteraction) {
    if (interaction.state === 'planned') {
      interaction.parameters
        .filter((p: Parameter) => (p.dataPoint && p.dataPoint.length > 0) || p.valueType === 'combined_multi_search').forEach((p) => {
          p.coercedValue = null;
        });
    }
  }

  async function _buildInteractionsForBulkClose(templateInteraction: IInteraction): Promise<IInteraction[]> {
    const bulkCloseUuid = uuid.v4();
    let bulkInteractions = interactions.value.filter((interaction) => interaction.id !== currentInteraction.value.id
      && bulkInteractionIds.value.includes(interaction.id as number));
    // need it to preserve reactivity on save
    bulkInteractions.push(currentInteraction.value);
    // TODO investigate why there are duplications sometimes
    bulkInteractions = bulkInteractions.filter((interaction, index) => bulkInteractions.indexOf(interaction) === index);
    return bulkInteractions.map((interaction) => ({
      ...interaction,
      ..._getCommonProperties(templateInteraction),
      bulkCloseUuid,
    }));
  }

  const _getCommonProperties = (templateInteraction: IInteraction) => ({
    serviceName: templateInteraction.serviceName,
    serviceId: templateInteraction.serviceId,
    providedServiceId: templateInteraction.providedServiceId,
    parameters: templateInteraction.parameters,
    computedParameters: templateInteraction.computedParameters,
    startAt: templateInteraction.startAt,
    needsStartAt: templateInteraction.needsStartAt,
    finishAt: templateInteraction.finishAt,
    responsiblePersonIds: templateInteraction.responsiblePersonIds,
    state: templateInteraction.state,
    manualAlarmState: templateInteraction.manualAlarmState,
    defaultNotes: templateInteraction.defaultNotes,
    notePublic: templateInteraction.notePublic,
  });
  const _getInteractionState = (state: string): string => (_unclosedState(state) ? state : 'closed');
  const _getManualAlarmState = (state: string): string => (_unclosedState(state) ? 'ok' : state);
  const _unclosedState = (state: string): boolean => ['planned', 'cancelled', 'sticky'].includes(state);

  function _valid(): boolean {
    if (!currentInteraction.value) return false;
    if (currentInteraction.value.handover && !currentInteraction.value.notePublic) {
      currentInteraction.value.errors = { handover: [translate('timeline.interaction.handoverRequiresNote')] };
      return false;
    }
    const hiddenParameters = currentInteraction.value.parameters.filter((p) => p.hidden);
    const hiddenKeys = hiddenParameters.map((p) => p.name);
    const hiddenIds = hiddenParameters.map((p) => p.id);
    currentInteraction.value.parameters.forEach((p) => {
      if (hiddenIds.includes(Math.floor(p.id as number))) {
        hiddenKeys.push(p.name);
      }
    });

    if (archivedValues.value) {
      const errors: string[] = [];
      Object.entries(archivedValues.value).forEach(([key, value]) => {
        if (value && !hiddenKeys.includes(key)) errors.push(`${translate('message.missing_value')} ${key}`);
      });
      if (errors.length > 0) {
        currentInteraction.value.errors = { parameterValuesArchivedValue: errors };
        return false;
      }
    }
    return true;
  }

  // this when new interactions?service is selected
  function _resetStoreState() {
    interactionLoaded.value = false;
    wasSavedInBackEnd.value = false;
    initialInteraction.value = {} as IInteraction;
    archivedValues.value = {};
  }

  // this when the right panel is unmounted
  function resetInteraction() {
    interactionLoaded.value = false;
    currentInteraction.value = {} as IInteraction;
  }

  function setParameterHidden(id: number | string, hidden: boolean) {
    const parameter = _getParameter(id);
    if (parameter) parameter.hidden = hidden;
  }

  function setParameterEdited(id: number | string) {
    const parameter = _getParameter(id);
    if (parameter) parameter.edited = true;
  }

  function getParameterShowIf(id: number | string): ShowIf | null {
    if (!currentInteraction.value) return null;
    const parameter = _getParameter(id);
    if (parameter?.config?.showIf) {
      const showIf = parameter.config.showIf.values();
      const targetCodename = showIf.next().value;
      if (!targetCodename?.length) return null;
      const regex = showIf.next().value;
      const target = _parameterCodenamesMap().get(targetCodename);
      return { target, regex };
    }
    return null;
  }

  function getParameterField(id: number | string) {
    const param = _getParameter(id);
    if (param?.valueType === 'file') {
      return param?.tempFile;
    }
    return param?.coercedValue;
  }

  async function calculateComputedParameters() {
    if (hasFilledInSignature.value) newSignatureCVSaved.value = false;
    if (!(currentInteraction.value?.parameters && currentInteraction.value.computable)) return;
    if (_errorMessageExist(currentInteraction.value)) return;
    const hasStateActions = interactionActionsStore.shouldRunActions(currentInteraction.value);
    if (_allRequiredParametersFilledIn(currentInteraction.value) || hasStateActions) {
      await _runCalculators(hasStateActions);
    }
  }

  async function calculateActions() {
    if (_errorMessageExist(currentInteraction.value)) return;
    await _runCalculators(true);
  }

  async function save() {
    const interaction = currentInteraction.value;
    if (!interaction) return;
    delete interaction.errors;

    _clearHiddenParametersValue(interaction);
    _maybeClearDataPointParametersValue(interaction);
    _maybeAssignInstanceId(interaction);

    const interactionsToSave = bulkInteractionIds.value.length <= 1 ? [interaction] : await _buildInteractionsForBulkClose(interaction);
    await Promise.all(interactionsToSave.map(async (interactionToSave) => {
      await _handleFiles(interactionToSave);
      const updatedInteraction = isNew.value
        ? await createInteraction(interactionToSave)
        : await updateInteraction(interactionToSave);
      if (!updatedInteraction.errors && updatedInteraction.id) interactionStore.updateInteraction(updatedInteraction);
      return updatedInteraction;
    })).then(async (updatedInteractions) => {
      if (updatedInteractions.length > 1) currentInteraction.value.errors = _getInteractionsErrors(updatedInteractions);
      if (!currentInteraction.value.errors) {
        if (updatedInteractions.length === 1 && updatedInteractions[0].id && hasActions.value) {
          await _updateStoresWithActionsEffects(updatedInteractions[0].id);
        }
        if (hasNourishInstanceParameter.value) {
          clientStore.reFetchSkinInstances(currentClientId.value as number);
        }
        wasSavedInBackEnd.value = true;
        if (hasDataPoint.value) dataPointsStore.reFetchById(currentClientId.value as number);
        if (bulkInteractionIds.value.length) useMultiSelectRegister().$reset();
      }
    });
  }

  const _maybeAssignInstanceId = (interaction: IInteraction) => {
    if (getFeatureToggle('bodyMapInstances')) {
      const { currentInstance } = storeToRefs(useServiceFilteringStore());
      if (currentInstance.value.instanceId) interaction.nourishInstanceId = currentInstance.value.instanceId;
    }
  };

  function _getInteractionsErrors(updatedInteractions: IInteraction[]) {
    return updatedInteractions.map((i) => i.errors)
      .filter((error) => !!error)
      .reduce(
        (acc, error) => ({ ...acc, ...error }),
        undefined,
      );
  }

  async function _updateStoresWithActionsEffects(currentInteractionId: number) {
    clientStore.reFetchById(currentInteraction.value?.clientId);
    interactionStore.loadChildInteractions(currentInteraction.value?.clientId, currentInteractionId);
  }

  function _backupCloseState(interaction: IInteraction): ISaveState {
    return {
      state: interaction.state,
      manualAlarmState: interaction.manualAlarmState,
      closedAt: interaction.closedAt,
      responsiblePersonIds: interaction.responsiblePersonIds,
    };
  }

  function restoreCloseState(interaction: IInteraction, closeState: ISaveState) {
    interaction.state = closeState.state;
    interaction.manualAlarmState = closeState.manualAlarmState;
    interaction.closedAt = closeState.closedAt;
    interaction.responsiblePersonIds = closeState.responsiblePersonIds;
  }

  function _prepareForSave(interaction: IInteraction, state: string) {
    const now = new Date().toISOString();
    currentInteraction.value.state = _getInteractionState(state);
    currentInteraction.value.manualAlarmState = _getManualAlarmState(state);
    if (currentInteraction.value.state === 'closed') {
      currentInteraction.value.closedAt = now;
      if (currentInteraction.value.responsiblePersonIds.length === 0 && currentUser.value.role !== 'informal_carer') {
        currentInteraction.value.responsiblePersonIds.push(currentUser.value.id);
      }
    }

    if (!interaction.finishAt && interaction.state !== 'sticky') {
      interaction.finishAt = now;
    } else if (interaction.state === 'sticky') {
      interaction.finishAt = null;
    }
  }

  async function saveInteraction(state: string): Promise<boolean> {
    if (!_valid()) return false;

    const oldState = _backupCloseState(currentInteraction.value);
    _prepareForSave(currentInteraction.value, state);
    await save();

    if (currentInteraction.value.errors) {
      restoreCloseState(currentInteraction.value, oldState);
      return false;
    }

    return true;
  }

  function getCoercedValueByParameterId(id: number): ComputedRef<CoercedValue | undefined> {
    return computed(() => {
      // TODO improve this, it loop over all parameters each time
      const param = currentInteraction.value?.parameters?.find((p) => p.id === id);
      if (!param) return undefined;
      return param.coercedValue;
    });
  }

  function setCoercedValueByParameterId(id: number, cv: CoercedValue): void {
    const param = currentInteraction.value?.parameters?.find((p) => p.id === id);
    if (!param) return;
    param.coercedValue = cv;
  }

  function _clearHiddenParametersValue(interaction: IInteraction) {
    interaction.parameters
      .filter((p: Parameter) => p.hidden).forEach((p) => {
        p.coercedValue = null;
      });
  }

  async function _handleFiles(interaction: IInteraction) {
    if (interaction?.parameters) {
      const fileParameters = interaction.parameters.filter((parameter) => parameter.valueType === 'file' || parameter.valueType === 'picture');
      await Promise.all(fileParameters.map(async (parameter) => {
        const coercedValue = parameter.coercedValue as FileWrapper;
        if (parameter.valueType === 'file') {
          const file = (coercedValue && coercedValue.file)
            || parameter.genericFile || null;
          if (file) {
            if (file.deleted && parameter.dataPoint) {
              await softDeleteFile(file, parameter);
            } else if (file.deleted) {
              await deleteFile(file, parameter);
            } else if (file.updatedLabel && interaction?.organisationUnitId) {
              await updateFile(file, parameter);
            }
          }
          if (parameter.tempFile) {
            await createFile(parameter, interaction);
          }
        } else {
          const pictureCoercedValue = parameter.coercedValue as PictureWrapper;
          if (pictureCoercedValue?.pictures?.length > 0) {
            await createFile(parameter, interaction);
          }
          if (blurredUpdated(parameter)) {
            const filesToUpdate: GenericFile[] = [];
            pictureCoercedValue.pictures.forEach((picture) => {
              if (picture.pictureFile) {
                picture.pictureFile.sensitive = pictureCoercedValue.blurred;
                filesToUpdate.push(picture.pictureFile);
              }
            });
            await Promise.all(filesToUpdate.map(async (fileToUpdate) => {
              await updateFile(fileToUpdate as GenericFile, parameter);
            }));
          }
          if (picturesToDelete.value.length > 0 && !parameter.dataPoint) {
            picturesToDelete.value.forEach(async (pictureToDelete) => {
              if (pictureToDelete.parameterId === parameter.id) {
                picturesToDelete.value = picturesToDelete.value
                  .filter((picture) => picture.pictureFile.id !== pictureToDelete.pictureFile.id);
                await deleteGenericFile(pictureToDelete.pictureFile as GenericFile);
              }
            });
          }
        }
      }));
    }
  }

  const blurredUpdated = (parameter: Parameter) => {
    const p = initialInteraction.value?.parameters?.find((param) => param.id === parameter.id);
    return !!(p && p.coercedValue && parameter.coercedValue && (p.coercedValue as PictureWrapper).blurred !== (parameter.coercedValue as PictureWrapper).blurred);
  };

  let prefilled = false;

  async function prefill() {
    if (currentInteraction.value?.clientId && currentInteraction.value?.serviceId) {
      prefilled = false;
      await getLastInteraction(currentInteraction.value.clientId, currentInteraction.value.serviceId)
        .then((lastInteraction: IInteraction) => {
          if (lastInteraction) {
            setPreviousInteractionState(currentInteraction.value);
            _prefillParameters(lastInteraction);
            _prefillNotePublic(lastInteraction);
            calculateComputedParameters();
            prefilled = true;
          }
        });
    }
    return prefilled;
  }

  function unprefill() {
    if (initialInteraction.value && prefilled) {
      _prefillParameters(initialInteraction.value);
      _prefillNotePublic(initialInteraction.value);
      calculateComputedParameters();
      prefilled = false;
    }
  }

  function _prefillParameters(lastInteraction: IInteraction) {
    currentInteraction.value?.parameters.forEach((parameter: Parameter) => {
      if (prefillableParameter(parameter)) {
        const lastParameter = _parameterFrom(lastInteraction, parameter.id);
        if (!lastParameter) {
          return;
        }
        parameter.coercedValue = JSON.parse(JSON.stringify(lastParameter.coercedValue));
      }
    });
  }

  async function prefillNourishInstanceParameters() {
    const instanceId = currentInteraction.value?.nourishInstanceId;
    if (instanceId && currentInteraction.value.state !== 'closed') {
      await until(() => skinInstances.value.length > 0);
      currentInteraction.value?.parameters
        .filter((parameter: Parameter) => parameter.valueType === 'nourish_instance')
        .forEach((parameter: Parameter) => {
          parameter.coercedValue = instanceToCoercedValue(instanceId);
          prefillSubParameters(parameter);
        });
      return true;
    }
    return false;
  }

  function instanceToCoercedValue(instanceId: number) {
    const skinInstance = skinInstances.value.find((instance: SkinInstance) => instance.id === instanceId);

    if (skinInstance) {
      return {
        existing: true,
        metadata: skinInstance.metadata,
        subType: skinInstance.subType,
      } as CoercedNourishInstance;
    }
    return {} as CoercedNourishInstance;
  }

  function prefillSubParameters(parameter: Parameter) {
    const locationSubParameter = currentInteraction?.value?.parameters.find((subParameter: Parameter) => subParameter.codename === `${parameter.id}_location`);
    const typeSubParameter = currentInteraction?.value?.parameters.find((subParameter: Parameter) => subParameter.codename === `${parameter.id}_specifictype`);
    const currentCoercedValue = parameter.coercedValue as CoercedNourishInstance;
    const location = currentCoercedValue.metadata?.location;
    const specificType = currentCoercedValue.metadata?.specificType;
    if (locationSubParameter) {
      (locationSubParameter.coercedValue as CoercedSingleSelect).values = location ? [location] : [];
    }
    if (typeSubParameter) {
      (typeSubParameter.coercedValue as CoercedSingleSelect).values = specificType ? [specificType] : [];
    }
  }

  function prefillableParameter(parameter: Parameter) {
    return !nonPrefillableParameter(parameter);
  }

  function nonPrefillableParameter(parameter: Parameter) {
    return ['signature', 'nourish_instance', ''].includes(parameter.valueType) || parameter.dataPoint;
  }

  function _prefillNotePublic(lastInteraction: IInteraction) {
    if (currentInteraction.value) {
      currentInteraction.value.notePublic = lastInteraction.notePublic;
    }
  }

  function _parameterFrom(interaction: IInteraction, parameterId: number | string) {
    return interaction.parameters.find((parameter) => parameter.id === parameterId);
  }

  async function _maybePatchParametersWithDataPoints() {
    const { parameters } = currentInteraction.value;
    if (!isClosed.value && parameters && hasDataPoint.value && currentClientId.value) {
      const complexParam = ['multi', 'medication', 'file', 'person_picker', 'single_selector_search', 'combined_multi_search'];
      const fetchedDatapoints = await dataPointsStore.asyncById(currentClientId.value);
      if (!fetchedDatapoints || !fetchedDatapoints.dataPoints) return;
      const paramsToProcess = parameters.filter(
        (p) => _dataPointPresent(p),
      );
      paramsToProcess.forEach((p) => {
        const paramType = p.valueType;
        let dataPointValues;
        if (paramType === 'combined_multi_search') {
          const keys = (p.config?.combinedMulti?.map((item) => valueToCamel(item.dataPoint as string))) || [];
          dataPointValues = Object.entries(fetchedDatapoints!.dataPoints!)
            .filter((entry) => keys.includes(entry[0]))
            .flatMap((entry) => entry[1].value as string);
        } else {
          const key = p.dataPoint ? valueToCamel(p.dataPoint as string) : 'contactPerson';
          dataPointValues = fetchedDatapoints!.dataPoints![key]?.value;
        }
        if (dataPointValues !== undefined) {
          if (!complexParam.includes(paramType)) {
            p.coercedValue = (paramType === 'number' && dataPointValues !== '')
              ? parseNumberDataPointValues(dataPointValues as string | CoercedChoice[] | CoercedChoice, p.isArray)
              : dataPointValues as string | CoercedChoice | CoercedChoice[];
          } else if (paramType === 'file') {
            p.coercedValue = dataPointValues ? { file: dataPointValues as GenericFile } as FileWrapper : null;
          } else if (paramType === 'picture') {
            p.coercedValue = (dataPointValues && dataPointValues instanceof Object) ? dataPointValues as FileWrapper : {};
          } else if (dataPointValues?.constructor === Array) {
            // TODO find a better way in back-end to send combined data point values when one/or more is as a NO question answer
            p.coercedValue ||= {};
            const cv = p.coercedValue as CoercedSelection;
            cv.values = (dataPointValues as string[]).filter((option) => !_getGlobalOptions()?.includes(option)) as string[];
            cv.answer = cv.values.length > 0 && p.config?.options ? getFirstConfigOption(p) : '';
          } else {
            const cv: Partial<CoercedSelection> = {
              values: [],
            };
            if (p.config?.question?.trim() === 'Yes') cv.answer = (typeof dataPointValues === 'string' || dataPointValues instanceof String) ? dataPointValues as string : '';
            p.coercedValue = cv;
          }
        }
        setParameterEdited(p.id);
      });
      if (parameters.length && parameters.some((p) => p.dataPoint)) {
        await calculateComputedParameters();
      }
    }
  }

  function _getGlobalOptions() {
    type NestedRecord = Record<string, Record<string, Record<string, Record<string, string[]>>>>
    const globalTranslations = window.I18n as NestedRecord;
    let globalOptions: Array<string> = [];
    const languages = Object.keys(globalTranslations);
    languages.forEach((language) => {
      const localisedOptions: Array<string> = (globalTranslations[language]).provided_service?.data_sets?.options;
      if (localisedOptions) {
        Object.values(localisedOptions).forEach((str) => {
          globalOptions = globalOptions.concat(str.split(' / '));
        });
      }
    });
    globalOptions = [...Array.from(new Set(globalOptions))];
    return globalOptions;
  }

  function _dataPointPresent(p: Parameter) {
    return (p.dataPoint && p.dataPoint.length)
      || (p.config?.combinedMulti && p.config.combinedMulti
        .filter((item) => item.dataPoint !== null)?.length > 0);
  }

  // TODO: Get from specifications of parameters
  const needsSummary = computed(() => true);
  const maybePatchCoercedChoices = (parameters: Parameter[]) => {
    parameters
      .filter((p) => hasMultipleChoices(p.coercedChoices))
      .forEach((p) => {
        if (p.coercedValue) {
          if (Array.isArray(p.coercedValue)) {
            p.coercedValue.forEach((value) => {
              _maybeAddCoercedValue(p, value);
            });
          } else {
            _maybeAddCoercedValue(p, p.coercedValue as CoercedChoice);
          }
        }
      });
  };

  const _maybeAddCoercedValue = (p: Parameter, coercedValue: CoercedChoice) => {
    if (!p.coercedChoices.some((choice) => shallowEqual(choice, coercedValue))) {
      p.coercedChoices.push(coercedValue);
    }
  };

  function resetAllSignatureParameters() {
    currentInteraction.value.parameters
      .filter((p) => p.valueType === 'signature')
      .forEach((p) => {
        p.coercedValue = null;
      });
    newSignatureCVSaved.value = false;
  }

  function undoTypedValues() {
    if (initialInteraction.value) {
      _prefillParameters(initialInteraction.value);
      _prefillNotePublic(initialInteraction.value);
      calculateComputedParameters();
    }
  }

  interface ISaveState {
    state: string;
    manualAlarmState?: string | boolean;
    closedAt: string | null;
    responsiblePersonIds: number[];
  }

  return {
    calculateComputedParameters,
    createdByAction,
    currentInteraction,
    changeCurrentInteraction,
    iconUrl,
    isPlannedInFuture,
    isNew,
    isCancelled,
    isClosed,
    isSticky,
    cannotBeClosed,
    closedAlarms,
    hasActions,
    hasAlarm,
    hasAnyCloseAlarmToDisplay,
    hasChanged,
    hasWarning,
    hasManualAlarm,
    hasDocuments,
    hasOldVersionSignatureParam,
    hasSavedSignatureParam,
    hasDataPoint,
    hasNFC,
    isClosedWithNoWarning,
    isViewOnly,
    needsSummary,
    setCurrentInteraction,
    setNewInteraction,
    getParameterField,
    setParameterHidden,
    setParameterEdited,
    shouldHideEmptyParameters,
    getParameterShowIf,
    save,
    picturesToDelete,
    prefill,
    prefillNourishInstanceParameters,
    unprefill,
    interactionLoaded,
    resetInteraction,
    saveInteraction,
    timelineDate,
    wasSavedInBackEnd,
    calculateActions,
    maybePatchCoercedChoices,
    initialInteraction,
    isNotBulkClose,
    getCoercedValueByParameterId,
    setCoercedValueByParameterId,
    archivedValues,
    showSignatureResetWarningModal,
    resetAllSignatureParameters,
    undoTypedValues,
    newSignatureCVSaved,
    setPreviousInteractionState,
  };
});

export default useCurrentInteractionStore;
